.Login_bg {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

#reg-layout {
  background-size: cover;
  position: relative;
  width: 100%;
}

#reg-layout .reg-box {
  background: #00000059;
  border-radius: 35px;
  box-shadow: 0 1px 0 #00000082, inset 0 0 8px #ffffff57;
  box-sizing: border-box;
  margin: 2rem auto;
  max-width: 26rem;
  padding: 4rem 2rem 1rem;
  position: relative;
  text-align: center;
  width: 100%;
}

a {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

#reg-layout .reg-box .reg-label {
  background: url(/public/assets/image/logos.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 4rem;
  left: 62%;
  position: absolute;
  top: 15px;
  transform: translate(-50%, 8px);
  width: 10rem;
}

#reg-layout .subtitle {
  color: #fff;
  display: block;
  font: 600 1.8rem / 2rem Cairo, sans-serif;
  font-size: 16px;
  padding: 0;
  text-align: center;
}

#reg-layout .btn.btn-secondary {
  background: linear-gradient(32deg, #729be3 50%, #a573ec) !important;
  font-size: 20px;
}

#reg-layout .btn {
  background: #758eb7;
  border-radius: 2.4rem;
  border-width: 0;
  box-shadow: 2px 3px 2px #a6a5a529;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font: 600 1.1rem Mukta Vaani, sans-serif;
  margin-bottom: 0.5rem;
  margin-top: 10px;
  max-width: 25rem;
  padding: 10px;
  text-decoration: none;
  transition: .2s linear;
  width: 100%;
}

#reg-layout .note {
  color: #fff !important;
  font: 600 1.6rem / 1.8rem Mukta Vaani, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  text-align: center;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

#reg-layout .form-input {
  background-color: #cecdcd00;
  border: 3px solid #fff;
  border-radius: 20px;
  box-sizing: border-box;
  color: #fff;
  display: block;
  font-weight: 700;
  height: 3rem;
  line-height: 8rem;
  padding: 0 1rem;
  transition: border-color .2s linear;
  width: 100%;
}

.loginagain {
  color: #fff !important;
}


.footer-section2 {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 700;
  height: 3.3rem;
  margin-top: 11px;
  position: inherit !important;
  text-align: center !important;
  width: 100% !important;
}