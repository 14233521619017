.Scroll {
  width: 100%;
  height: 15.625rem;
  border: 0.0625rem solid #ccc; 
  overflow-y: auto;
  padding: 0.00625rem;
}

.mainmodal .modal-dialog {
  width: 20.625rem;
}

.mainmodal .modal-dialog .modal-content {
  border-radius: 1.25rem; 
  height: 30rem; 
}

.mainmodal .modal-dialog .modal-header {
  text-align: center;
  justify-content: center;
  border-radius: 1.25rem 1.25rem 0 0; 
  background: var(--main_gradient-color);
  color: white;
  border: none;
}

.mainmodal .modal-header .modal-title {
  font-size: 1.375rem; 
}

.mainmodal .modal-footer button {
  font-size: 0.9375rem;
  height: 2.1875rem; 
  background: var(--main_gradient-color);
  color: white;
  border: 0;
  width: 50%;
  border-radius: 1.875rem; 
  font-weight: bold;
  margin: auto;
}

/* Modal 2 */
.secondmodal {}

.secondmodal .modal-dialog {
  width: 20.625rem; 
}

.secondmodal .modal-dialog .modal-content {
  border-radius: 1.25rem; 
  height: 35.3125rem; 
}

.secondmodal .modal-dialog .modal-header {
  text-align: center;
  justify-content: center;
  border-radius: 1.25rem 1.25rem 0 0; 
  background: var(--main_gradient-color);
  color: white;
  border: none;
}

.secondmodal .modal-header .modal-title {
  font-size: 1.375rem;
}

.secondmodal .modal-header .modal-title p {
  font-size: 0.875rem;
}

.secondmodal .card {
  background-color: #f6f6f6;
  border-radius: 0.9375rem; 
  box-shadow: none;
  margin-top: 0.625rem; 
  margin-bottom: 0.625rem; 
}

.secondmodal .card .card-body p {
  font-size: 0.875rem;
  color: black;
}

.secondmodal .card .card-body p span {
  color: #FEAA57;
  font-size: 0.875rem;
}

.secondmodal .card .card-body .orangebtn {
  color: #FEAA57;
  font-size: 0.875rem;
}

.secondmodal .card .card-body .description {
  font-size: 0.875rem;
  color: #768096;
}

.secondmodal .card .card-body .foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secondmodal .card .card-body .foot .step {
  background-color: #d8d8d8;
  height: 1.25rem;
  font-size: 0.875rem;
  color: black;
  width: 6.25rem; 
  text-align: center;
  border-radius: 1.875rem;
}

.secondmodal .card .card-body .foot .n2 {
  border: 0.0625rem solid #FEAA57; 
  color: #FEAA57;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  padding: 0;
  height: 1.8125rem;
  width: 6.25rem;
  text-align: center;
  line-height: 1.625rem;
  font-weight: 600;
}

.orangetext {
  background-color: #FEAA57 !important;
  color: black !important;
}

.secondmodal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secondmodal .modal-footer label {
  font-size: 0.8125rem;
}

.secondmodal .modal-footer button {
  height: 1.875rem; 
  line-height: 1.25rem; 
  border: 0;
}

.secondmodal .form-check-input {
  border-color: var(--main-color);
  border-radius: 1.875rem; 
}

.secondmodal .form-check-input:checked {
  background-color: var(--main-color);
  border-color: var(--main-color);
  border-radius: 1.875rem; 
}

.secondmodal .modal-dialog .modal-content {
  height: 35.3125rem;
}

@media only screen and (min-width: 320px) and (max-width: 576.99px) {
  .mainmodal .modal-dialog {
    width: 18.75rem;
    margin: auto;
    transform: translateY(2.75rem) !important;
  }

  .mainmodal .modal-dialog p {
    font-size: 0.8125rem;
  }

  .mainmodal .modal-header .modal-title {
    font-size: 1.125rem; 
  }

  .mainmodal .modal-dialog .modal-content {
    height: 28.75rem;
  }

  .secondmodal .modal-dialog .modal-content {
    height: 35rem;
  }

  .secondmodal .modal-dialog {
    width: 18.75rem;
    margin: auto;
    transform: translateY(2.75rem) !important; 
  }

  .secondmodal .modal-header .modal-title {
    font-size: 0.9375rem; 
  }

  .secondmodal .modal-header .modal-title p {
    margin-bottom: 0;
  }

  .secondmodal .card .card-body .description {
    font-size: 0.75rem; 
  }

  .secondmodal .modal-footer {
    padding: 0.3125rem; 
  }
}
