.dashMain{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.dashfl{
  display: flex;
  justify-content: space-between;
}
.useft{
  font-size: 30px;
}
.skh{
  background: #00000059;
  border-radius: 10px;
  box-shadow: 0 1px 0 #00000082, inset 0 0 8px #ffffff57;
    color: white;
    padding: 25px;
    
}
.imgBrand{
  width: 90px;
}

.jkjsnfkse{
  justify-content: flex-end !important;
}

.dashboardbtn span {
  width: 35px;
  height: 35px;
}

.dashboardbtn {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  padding: 20px;
  background-color: #000;
  border-radius: 50%;
}
.para1{
  display: inline-block;
  font-size: 18px;
  margin-top: 0px;
  border: 1px solid #101010;
  background: #0a0a1b;
  color: #fff;
  margin: 10px;
  border-radius: 7px;
  width: 100%;
  text-align: center !important;
  box-shadow: inset 0px 0px 6px 2px #fff;
  padding: 10px 15px;
}
.magicbtn {
  font-size: 17px;
  overflow: hidden;
  background: #00000059;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 1px 0 #00000082, inset 0 0 8px #ffffff57;
  /* width: 224px; */
  text-align: center;
  
  
}

.magicbtn2 {
  font-size: 17px;
  overflow: hidden;
  background: #00000059;
  border-radius: 10px;
  box-shadow: 0 1px 0 #00000082, inset 0 0 8px #ffffff57;
  /* width: 224px; */
  /* text-align: center; */
  height: 100px;
  
}

.rounded-5{
  border-radius: 2rem;
}


.dashboardSection .homecardround .card .card-body .cardleftimage {
    width: 60px;
    height: 60px;
    transition: all 0.3s ease;
    filter: drop-shadow(-10px 10px 20px rgba(8, 76, 186, 0.4666666667));
    animation: scale_up_down 1s ease-in-out infinite alternate both;
}

@media (max-width: 1199.98px){
  .dashboardSection .homecardround .card .card-body .cardleftimage {
    width: 40px;
    height: 40px;
    margin: auto;
}

.dashboardSection .homecardround .card {
    overflow: hidden;
    padding: 20px;
}}

.dashboardSection .homecardround .card {
    flex: 0 0 auto;
    overflow: hidden;
    padding: 20px;
    margin-right: 5px;
}
.bgss {
    height: 135px;
}

.dashboardSection2 .leftSideimage {
  position: absolute;
  left: 0;
  bottom: -10%;
  z-index: -1;
}

.dashboardSection2 .rightSideimage {
  position: absolute;
  right: 0;
  top: 5%;
  z-index: -1;
}




.dashboardSection2 .middleSection {
  background: linear-gradient(135deg, #4b87b1, #493d05);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.copyimage {
  cursor: pointer;
}

@media (max-width: 575.98px){
.dashboardSection .homecardround .card {
    flex: 0 0 auto;
    width: 100%;
    overflow: hidden;
    padding: 20px;
}
.skh{
  margin-bottom: 10px;
}
.magicbtn2 {
  margin-bottom: 10px;
}
.dashboardSection2 .middleSection .heading {
  text-align: center;

}

.dashboardSection2 .middleSection .copy-input .form-control {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.leftSideimage, .rightSideimage{
  display: none;
}

.navbar-nav .dropdown-menu{
  display: flex !important;
  flex-direction: column !important;
  position: sticky;
}
.headingDashboard {
  font-size: 28px;
}
}

@media (min-width: 576px){
.px-sm-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}}
.align-items-center {
  align-items: center !important;
}
.h-100 {
  height: 100% !important;
}
.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.end-0 {
  right: 0 !important;
}
.top-0 {
  top: 0 !important;
}
.position-absolute {
  position: absolute !important;
}
.flexd{
  display: flex;
  justify-content: space-between;
}
.jshsa{
  background: #00000059;
    border-radius: 35px;
    box-shadow: 0 1px 0 #00000082, inset 0 0 8px #ffffff57;
    box-sizing: border-box;
    /* margin: 2rem auto; */
    /* max-width: 26rem; */
    padding: 1rem 1rem 1rem;
    position: relative;
    text-align: center;
    width: 100%;
}
.btto{
  border-bottom: 1px solid #ff963c;
    padding-bottom: 10px;
}
.kijsdiwj{
  flex-direction: column;
}

.backgroundcolor {
  background: #00000059;
    border-radius: 35px;
    box-shadow: 0 1px 0 #00000082, inset 0 0 8px #ffffff57;
}