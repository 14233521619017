/* @media (max-width: 767px) {
    .header {
      display: none; 
    }
  
    .mobile-menu-area {
      display: block; 
    }
  } */


  @media (min-width: 768px) {
    .mobile-menu {
      display: none;
    }
  }
  @media (min-width: 320px) and (max-width: 575.99px) {
    .menu-box .menu-wraper .right-content .button a{
      padding: 11px 16px;
      font-size: 13px;
    }
  }
  